import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import Input from "./Input";
import { Link, useNavigate } from "react-router-dom";
import {
  formGetValue,
  formGetValues,
  formOptions,
  getValue,
} from "./formUtils";
import moment from "moment";
import {inputVisible} from "../../../services/utils";
const _ = require("lodash");

const FormsForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    validate() {
      formik.validateForm();
    },
    validation() {
      return Object.keys(formik.errors).length === 0 ? false : formik.errors;
    },
    submit() {
      const formikValues = submitValues(formik.values);
      const values = {};
      Object.keys(columns).map(index => {
        const column = columns[index];
        const attr = column.field;
        if (column.fieldModel) {
          if (!Object.keys(values).includes(column.fieldModel)) {
            values[column.fieldModel] = {};
          }
          if (column.fieldModelArrayIndex !== undefined) {
            if (!values[column.fieldModel][column.fieldModelArrayIndex]) {
              values[column.fieldModel][column.fieldModelArrayIndex] = {};
            }
            values[column.fieldModel][column.fieldModelArrayIndex][attr] =
              formikValues[attr] ?? defaultValue(column);
            if (
              column.fieldModelId &&
              !values[column.fieldModel][column.fieldModelArrayIndex].id
            ) {
              values[column.fieldModel][column.fieldModelArrayIndex].id =
                column.fieldModelId;
            }
          } else {
            values[column.fieldModel][attr] = formikValues[attr] ?? defaultValue(column);
            if (column.fieldModelId && !values[column.fieldModel].id) {
              values[column.fieldModel].id = column.fieldModelId;
            }
          }
        } else {
          values[attr] = formikValues[attr] ?? defaultValue(column);
          if (column.fieldModelId && !values.id) {
            values.id = column.fieldModelId;
          }
        }
      });
      if (submitFunc) {
        return submitFunc(values);
      }
    },
    returnInput(field) {
      if (refInputs.current[field]) {
        return refInputs.current[field];
      }
      return null;
    },
    returnInputs() {
      return refInputs.current;
    },
  }));

  const {
    id,
    editable,
    header,
    permissions,
    permissionParam,
    columns,
    getFunc,
    setFunc,
    getObj,
    submitFunc,
    isDense,
    tint,
    columnStartsWith,
    ...args
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [isEditable, setIsEditable] = useState(editable);
  const [originalData, setOriginalData] = useState({});
  const [formData, setFormData] = useState({});
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  // const refForm = useRef(null);
  const navigate = useNavigate();

  const refInputs = useRef({});

  const defaultValue = (column) => {
    if (column.defaultValue) {
      return column.defaultValue;
    } else {
      switch (column.fieldType) {
        case "number":
        case "select":
          return 0;
          break;
        case "checkbox":
          return [];
          break;
        case "boolean":
          return false;
          break;
        default:
          return "";
      }
    }
  }

  const validate = (values) => {
    const errors = {};

    const addError = (field, msg, column) => {
      if (errors[field] === undefined) {
        errors[field] = {
          msg,
          columns: [column],
        };
      } else {
        errors[field].columns.push(column);
      }
    };

    if (loaded) {
      columns.map((column) => {
        const options = formOptions(column, args);
        const val = formGetValue(formik, column, options, values);
        if (typeof column.validation === "function") {
          const errorResponse = column.validation(values[column.field]);
          if (errorResponse) {
            addError(column.field, errorResponse, column);
          }
        } else if (
          column.validation?.required &&
          column.fieldType === "text" &&
          !val
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "texteditor" &&
          (val === "" || val === "<p></p>")
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "texteditorlight" &&
          (val === "" || val === "<p></p>")
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "date" &&
          (val === "" || !moment(val))
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "number" &&
          (isNaN(parseInt(val)) || parseInt(val) === 0)
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "select" &&
          (isNaN(parseInt(val)) || parseInt(val) === 0)
        ) {
          addError(column.field, "Kötelező", column);
        } else if (
          column.validation?.required &&
          column.fieldType === "autocomplete"
        ) {
          if (!val || val.id === 0) {
            addError(column.field, "Kötelező", column);
          }
        } else if (
          column.validation?.required &&
          column.fieldType === "checkbox"
        ) {
          if (val && val.length === 0) {
            addError(column.field, "Kötelező", column);
          }
        } else if (
          column.validation?.minLength &&
          val &&
          val.length < column.validation.minLength
        ) {
          addError(
            column.field,
            `Legalább ${column.validation.minLength} karakter legyen!`,
            column
          );
        }
      });
      // console.log("form errors", errors);
    }
    return errors;
  };

  const formik = useFormik({
    validate,
    onSubmit: (values, formikBag) => {
      setSubmitting(true);
      const submittedValues = submitValues(values);
      if (!submitFunc && setFunc) {
        setFunc(id, submittedValues)
          .then((response) => {
            setSubmitting(false);
            if (response.data) {
              if (!id && response.data.id) {
                navigate(`${header.url}/${response.data.id}`);
              }
            } else {
              navigate(header.url);
            }
          })
          .catch((err) => console.error(err));
      }
    },
  });

  useEffect(() => {
    return () => {
      setDirty(false);
    }
  }, []);

  useEffect(() => {
    if (typeof permissions === "function") {
      permissions(permissionParam)
        .then((response) => {
          setCurrentPermissions(response.data);
        })
        .catch((err) => console.error(err));
    } else if (Array.isArray(permissions)) {
      setCurrentPermissions(permissions);
    }
    return () => {
      setCurrentPermissions([]);
    };
  }, [permissions]);

  useEffect(() => {
    setIsEditable(editable);
    return () => {
      setIsEditable(false);
    };
  }, [editable]);

  useEffect(() => {
    if (!loaded) {
      if (getObj) {
        const values = formGetValues(formik, columns, getObj, args);
        // console.log("getObj", values);
        setFormData(values);
        setOriginalData(Object.assign({}, values));
      } else {
        if (id > 0) {
          getFunc(id)
            .then((response) => {
              const values = formGetValues(
                formik,
                columns,
                response.data,
                args
              );
              // console.log("getFunc", values);
              setFormData(values);
              setOriginalData(Object.assign({}, values));
              // formik.resetForm({ values: response.data });
            })
            .catch((err) => console.error(err));
        } else {
          const emptyForm = {};
          columns.map((column) => {
            emptyForm[column.field] = defaultValue(column);
          });
          // console.log("emptyForm");
          setFormData(emptyForm);
        }
      }
    }
  }, [id, getObj, columns]);

  useEffect(() => {
    if (Object.keys(formData).length > 0 || !id) {
      formik.resetForm({ values: formData });
      setLoaded(true);
    }
  }, [formData]);

  const submitValues = (values) => {
    const submitting = {};
    if (columns) {
      columns.map((column) => {
        if (column) {
          const options = formOptions(column, args);
          let val = formGetValue(formik, column, options, values);
          if (column.submitTransform) {
            val = column.submitTransform(val);
          }
          if (column.fieldType === 'texteditor') {
            const input = refInputs.current?.[column.field]?.returnInput();
            if (input) {
              val = input.returnValue();
            }
          }
          if (val !== null) {
            submitting[column.field] = val;
          }
        }
      });
    }
    return submitting;
  };

  let columnIndex = columnStartsWith ?? -1;
  return (
    <>
      <div className={`card ${isDense ? "border-0" : ""}`}>
        {/* begin::Header */}
        {!header.skip && (
          <div className="card-header border-0 pt-5">
            <Grid container width={"100%"} alignItems={"center"}>
              <Grid flexGrow={1}>
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    {`${header.item
                      .substring(0, 1)
                      .toUpperCase()}${header.item.substring(1)}`}{" "}
                    {!currentPermissions.includes("update") &&
                    !currentPermissions.includes("create")
                      ? "megtekintése"
                      : id
                      ? "szerkesztés"
                      : "hozzáadás"}
                  </span>
                </h3>
              </Grid>
              <Grid>
                <Link to={header.url}>
                  <Button variant={"contained"} color={"primary"}>
                    Vissza
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        )}
        <div className={`card-body ${isDense ? "p-0" : "py-3"}`}>
          {currentPermissions.includes("read") && (
            <div className="collapse show">
              {loaded && formik && columns && (
                <form
                  noValidate="novalidate"
                  className="form"
                  onSubmit={formik.handleSubmit}
                >
                  <div className={`card-body ${isDense ? "p-0" : "p-9"}`}>
                    {columns.map((column, key) => {
                      const { editMode, visible } = inputVisible(isEditable, column, permissions);
                      if (visible) {
                        columnIndex++;
                      }
                      return (
                        <Input
                          ref={(el) =>
                            (refInputs.current[column.field ?? key] = el)
                          }
                          key={column.field ?? key}
                          tintColumns={tint ?? false}
                          columnIndex={columnIndex}
                          isLast={key === columns.length - 1}
                          isEditable={isEditable}
                          formik={formik}
                          initialValue={getValue(
                            formData,
                            column,
                            formOptions(column, args)
                          )}
                          isFormDirty={dirty}
                          column={column}
                          permissions={currentPermissions}
                          values={getObj}
                          dirtying={(force = false) => {
                            if (force) {
                              setDirty(true);
                            } else {
                              const formikValues = formGetValues(
                                  formik,
                                  columns,
                                  formik.values,
                                  args
                              );
                              const originalValues = formGetValues(
                                  formik,
                                  columns,
                                  originalData,
                                  args
                              );
                              const isDirty = !_.isEqual(
                                  formikValues,
                                  originalValues
                              );
                              setDirty(isDirty);
                            }
                          }}
                          {...args}
                        />
                      );
                    })}
                    {isEditable &&
                      !submitFunc &&
                      (currentPermissions.includes("update") ||
                        currentPermissions.includes("create")) && (
                        <div className="row">
                          <div className="col-xs-12 text-right">
                            <LoadingButton
                              type={"submit"}
                              disabled={!formik.isValid || !dirty}
                              variant={"contained"}
                              color={
                                formik.isValid && dirty ? "success" : "error"
                              }
                              loading={submitting}
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                            >
                              <span>Mentés</span>
                            </LoadingButton>
                          </div>
                        </div>
                      )}
                  </div>
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Forms = forwardRef(FormsForwardRef);
Forms.propTypes = {
  id: PropTypes.number,
  editable: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    .isRequired,
  permissionParam: PropTypes.object,
  columns: PropTypes.array.isRequired,
  getFunc: PropTypes.func,
  setFunc: PropTypes.func,
  getObj: PropTypes.object,
  submitFunc: PropTypes.func,
  isDense: PropTypes.bool,
};
export default Forms;
